/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MKTypography from "../../../../components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={1} alignItems="center">
          <Grid xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <MKTypography variant="h5">
                  Can I apply offers from multiple providers simultaneously?
                </MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography variant="body2">
                  <MKTypography variant="body2" fontWeight="bold" color="error">
                    NO
                  </MKTypography>
                  You cannot apply offers from multiple providers simultaneously. When you activate
                  an offer from a different provider, any previously activated offers will be
                  deactivated.
                </MKTypography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <MKTypography variant="h5">
                  Can I check which provider is activated in Pointie?
                </MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography variant="body2">
                  <MKTypography variant="body2" fontWeight="bold" color="error">
                    NO
                  </MKTypography>
                  Unfortunately, it is currently not supported.
                </MKTypography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <MKTypography variant="h5">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  After clicking Collect and come back in, but I can't see Activated?
                </MKTypography>
              </AccordionSummary>
              <AccordionDetails>
                <MKTypography variant="body2">
                  In conclusion,&nbsp;
                  <b>
                    the activation may not show in Pointie depends on a web site or provider, but it
                    may actually be activated
                  </b>
                  . Pointie uses the referrer value to determine if it has come from a However,
                  However, this is not 100% guaranteed depending on a website or a provider.
                </MKTypography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
