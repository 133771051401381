import { CardMedia } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import screenshotImg from "../../../assets/images/pointie_screenshot.jpg";
const EmbedScreenshotAndVideo = () => {
  return (
    <Container>
      <Grid container item lg={12} justifyContent="center" sx={{ mx: "auto", textAlign: "center" }}>
        <img src={screenshotImg} style={{ width: "90%", borderRadius: "1vw" }} />
      </Grid>

      <Grid
        container
        item
        mt={2}
        lg={12}
        justifyContent="center"
        sx={{ mx: "auto", textAlign: "center" }}
      >
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%" /* 16:9 비율을 위한 값을 설정합니다 (9 / 16 * 100) */,
            height: 0,
            width: "100%",
            overflow: "hidden",
          }}
        >
          <CardMedia
            sx={{
              position: "absolute",
              marginLeft: "0%",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              marginTop: "0px",
            }}
            md={{
              position: "absolute",
              marginLeft: "10%",
              top: 0,
              left: 0,
              width: "90%",
              height: "90%",
            }}
            component="iframe"
            src="https://www.youtube.com/embed/9-xvR2TpfVI?autoplay=0&mute=1&vq=hd720"
            mb={2}
          />
        </div>
      </Grid>
    </Container>
  );
};

export default EmbedScreenshotAndVideo;
